<script>
export default {}
</script>

<template>
    <div class="card border-dark mb-3 text-center mx-auto" style="max-width: 20rem;">
        <div class="card-header" style="background-color: #0b0b0b37;">No Pages found</div>
        <div class="card-body" style="background-color: #0b0b0b08;">
            <p>No pages have been added. To get started you can:
                <router-link to="/website/sitemap" class="nav-link">
                    Import pages from your sitemap
                </router-link>

                or<br>

                <router-link to="/website/webpage" class="nav-link">
                    Add pages manually
                </router-link>
            </p>
        </div>
    </div>
</template>