<script>
import { mapGetters } from 'vuex'
import api from '@/services/secureHttps';

export default {
  name: 'TreeItem', // necessary for self-reference
  emits: {
    'onPageSelected'(property) {
      return property;
    }
  },
  props: {
    model: Object
  },

  data() {
    return {
      // isOpen: false,
      data: this.model
    }
  },
  computed: {
    ...mapGetters('context', [
      'selectedWebisteId'
    ]),
    isFolder() {
      return this.data.pages && this.data.pages.length;
    }
  },

  methods: {
    toggle(pageId) {
      if (this.isFolder) {
        this.data.isOpen = !this.data.isOpen;
      } else {
        this.fetchPageInfo(pageId);
      }
    },
    changeType() {
      if (!this.isFolder) {
        this.data.pages = [];
        this.data.isOpen = true;
      }
    },
    fetchPageInfo(pageId) {
      api.getPageInfo(this.selectedWebisteId, pageId)
        .then(response => {

          this.$emit('onPageSelected', response.data);
        });
    },

    handleOnPageSelected(pageInfo) {
      this.$emit('onPageSelected', pageInfo);
    }
  }
}
</script>

<template>
  <li v-show="data.isVisible" class="list-group-item">
    <div :class="[isFolder ? 'fw-medium' : 'text-muted']" @dblclick="changeType"
      class="highlightHover">

      <span v-if="isFolder">
        <span v-if="data.isOpen" class="mdi mdi-chevron-down"></span>
        <span v-else class="mdi mdi-chevron-right"></span> 
      </span>
          <h4 v-if="data.isRootNode" style="display:inline"  >{{ data.name }} </h4>
          <span v-else  @click="toggle(model.id)" >{{ data.name }}</span>
      <div v-if="!isFolder" class="icon">
        <a :href="data.url" target="_blank">open link</a>
      </div>
      <div  class="icon me-2">
        <i v-if="data.hasMetaData !== null && data.hasMetaData === true" class="ri-code-s-slash-line"
          title="Has meta data"></i>
      </div>

    </div>

    <ul v-show="data.isOpen" v-if="isFolder" class="basicList list-group-flush">
      <!--
        A component can recursively render itself using its
        "name" option (inferred from filename if using SFC)
      -->
      <TreeItem v-for="model in data.pages" :model="model" :key="model.id" @onPageSelected="handleOnPageSelected">
      </TreeItem>
    </ul>
  </li>
</template>

<style scoped>
.basicList {
  list-style-type: none;
}

.noBorder {
  border: none
}

.highlightHover:hover {
  text-decoration: underline;
}

.icon {
  text-decoration: none;
  float: right;
}
</style>

