<script>
import TreeItem from './tree-item.vue'
import api from '@/services/secureHttps';
import noPages from "@/components/nestedSearch/ns-noPages.vue";
//import { toRaw } from 'vue';

export default {
    emits: ["onPageSelected"],

    components: {
        TreeItem,
        noPages
    },

    props: {
        websiteId: {
            type: Number,
            required: true,
        },
        getOnlyEditPages: {
            type: Boolean,
            required: false,
            default: false
        },
        resultsMaxHeight: {
            type: Number,
            required: false,
            default: null
        }
    },

    data() {
        return {
            website: null,
            queryValue: '',

        }
    },

    created() {
        this.fetchData();
    },

    watch: {
        websiteId: function () {
            this.fetchData();
        }
    },

    computed: {
        scrollableStyle() {
            return {
                'max-height': this.resultsMaxHeight + "px",
                'overflow-y': 'auto'
            };
        },

        hasPages() {
            console.log('hasPages', this.website);
            if (this.website !== null) {
                if (typeof this.website.pages !== 'undefined') {
                    return this.website.pages.length > 0;
                }
            }

            return false;
        }

    },

    methods: {
        async fetchData() {
            this.website = null;

            if (this.getOnlyEditPages === true) {
                api.getEditPagesOnly(this.websiteId)
                    .then(response => {
                        console.log("only edit website data", response);
                        this.handleApiResponse(response);
                    })
                    .catch(e => {
                        console.log(e);
                    })
            } else {
                api.getAllPages(this.websiteId)
                    .then(response => {
                        console.log("website data", response);
                        this.handleApiResponse(response);
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
        },

        handleApiResponse(response) {
            this.website = response.data;
            console.log(this.website);
            this.website.isVisible = true;
            this.website.isOpen = true;
            this.website.isRootNode = true;
            this.setupWebsiteData(this.website.pages);
            console.log(this.website);
        },

        queryForPages(event) {
            const value = event.target.value;
            this.queryValue = value.toLowerCase();

            if (this.queryValue.length <= 2) {
                this.resetToAllVisible(this.website.pages);
                return;
            }

            this.filterItems(this.website.pages);
        },

        filterItems(pages, forceVisibility) {
            var hasVisibility = false;

            for (let i = 0; i < pages.length; i++) {
                let item = pages[i];
                var isVisible = false

                if (item.name.toLowerCase().includes(this.queryValue)) {
                    isVisible = true;
                    hasVisibility = true;
                }

                if (typeof (forceVisibility) !== 'undefined' && forceVisibility === true) {
                    isVisible = true;
                    hasVisibility = true;
                }

                item.isVisible = isVisible;


                if (typeof item.pages !== 'undefined') {
                    // ensures sub pages/paths are visible
                    var isParentNodeVisible = this.filterItems(item.pages, isVisible);

                    if (hasVisibility === false) {
                        hasVisibility = isParentNodeVisible;
                    }

                    item.isVisible = isParentNodeVisible;
                    item.isOpen = isParentNodeVisible;

                }
            }

            return hasVisibility;
        },

        setupWebsiteData(pages) {
            for (let i = 0; i < pages.length; i++) {
                let item = pages[i];
                item.isVisible = true;

                if (typeof item.pages !== 'undefined') {
                    // item.isFolder = true;
                    item.isOpen = false;

                    this.setupWebsiteData(item.pages);
                }
            }
        },

        resetToAllVisible(pages) {
            for (let i = 0; i < pages.length; i++) {
                let item = pages[i];
                item.isVisible = true;

                if (typeof item.pages !== 'undefined') {
                    item.isOpen = false;
                    this.resetToAllVisible(item.pages);
                }
            }
        },

        handleOnPageSelected(pageInfo) {
            console.log("nested search pageInfo", pageInfo);
            this.$emit('onPageSelected', pageInfo);
        }
    }
}

</script>

<template>
    <!--start non scrollable view -->
    <div v-if="resultsMaxHeight == null" class="row">
        <div class="col-xl-12 mt-1">
            <input type="text" class="form-control" @keyup="queryForPages"
                placeholder="Search for a page and select from the results">
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="mt-2">
                    <div>
                        <ul class="basicList list-group-flush">
                            <TreeItem class="item" v-if="website" :model="website" @onPageSelected="handleOnPageSelected">
                            </TreeItem>
                        </ul>
                    </div>
                    <div v-show="hasPages === false">
                       <noPages></noPages>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end non scrollable view-->

    <!--start scrollable view -->
    <div v-else class="row">
        <div class="col-xl-12 mt-1">
            <input type="text" class="form-control" @keyup="queryForPages"
                placeholder="Search for a page and select from the results">
        </div>

        <div class="row mt-3" :style="scrollableStyle">
            <div class="col-xl-12">
                <div class="mt-2">
                    <div>
                        <ul class="basicList list-group-flush">
                            <TreeItem class="item" v-if="website" :model="website" @onPageSelected="handleOnPageSelected">
                            </TreeItem>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end scrollable view -->
</template>

<style scoped>
.basicList {
    list-style-type: none;
}
</style>

