<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import WebsiteSearch from "@/components/nestedSearch/nested-search.vue";

import { mapGetters } from 'vuex';

export default {
    components: {
        Layout,
        PageHeader,
        WebsiteSearch
    },

    data() {
        return {
            title: "Choose Page To Edit",
            items: [],
            websiteMetaDataItems: [],
        };
    },

    computed: {
        ...mapGetters('context', [
            'selectedWebisteId'
        ])
    },

    methods: {
        handleOnPageSelected(pageInfoObj) {
            var pageId = pageInfoObj.id;
            var pageUrl = pageInfoObj.website.websiteUrl.slice(0, -1) + pageInfoObj.path
            console.log(pageUrl);

            this.$router.push({ name: 'webPageEditor', params: { pageId: `${pageId}`, url: `${pageUrl}` } });

        }
    }
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col">
                <div class="card card-height-100">
                    <div class="card-header align-items-center d-flex">
                                <p class="
                                    text-uppercase
                                    fw-bold
                                    text-truncate
                                    mb-0
                                    ">
                                    Select Page to Edit
                                </p>
                            </div>
                    <!-- end card header -->

                    <!-- card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <p>The Page Editor is a valuable resource for implementing instant on-page enhancements to websites. This dynamic editor, 
                                    paired with Semantic click script, empowers you to modify, deploy, and assess webpage components in real-time.</p>
                                <WebsiteSearch :websiteId="selectedWebisteId" @onPageSelected="handleOnPageSelected" />
                            </div>
                        </div>
                        <!-- end card body-->
                    </div>

                </div>
            </div>
        </div>
    </Layout>
</template>